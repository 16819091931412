<template>
  <b-sidebar
    id="add-new-rule-sidebar"
    :visible="isAddNewRuleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetFormCustom"
    @change="(val) => $emit('update:is-add-new-rule-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Rule Item
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          ref="form"
          class="p-2"
          :style="{height: trHeight}"
          @submit.prevent="repeateAgain"
          @reset.prevent="resetFormCustom"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="10">
              <b-form-group
                label="Rule Items"
                label-for="rule-items"
              >
                <validation-provider
                  #default="{ errors }"
                  name="rule item"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="ruleData.rules[index].rule"
                    placeholder="Enter New Rule Items"
                    :state="errors.length > 0 ? false:null"
                  >
                    <small
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-textarea>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="ml-2"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="ml-2 mr-2"
          @click="validationForm"
        >
          Save
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    BFormTextarea,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isAddNewRuleSidebarActive',
    event: 'update:is-add-new-rule-sidebar-active',
  },
  props: {
    isAddNewRuleSidebarActive: {
      type: Boolean,
      required: true,
    },
    ruleMainId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      items: [{
        id: 1,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  setup() {
    const blankRuleData = {
      rules: [{ id: '', rule: '' }],
    }

    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))
    const resetuserData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      blankRuleData,
      ruleData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    resetFormCustom() {
      this.items = [{
        id: 1,
      }]
      this.nextTodoId = 2
      this.resetForm()
      this.initTrHeight()
    },
    validationForm() {
      const self = this
      return new Promise((resolve, reject) => {
        self.$refs.refFormObserver.validate().then(success => {
          if (success) {
            self.$store.dispatch('app-rules/updateRule', {
              data: self.ruleData,
              id: this.ruleMainId,
            })
              .then(res => {
                if (res.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Rule Added',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: res.data.message,
                    },
                  })
                  self.$emit('refetch-data')
                  self.$emit('update:is-add-new-rule-sidebar-active', false)
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Oops! Rule Adding Failed',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: res.data.message,
                    },
                  })
                }
              })
              .catch(error => {
                // console.log(error)
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Oops! Rule Adding Failed',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error,
                  },
                }, {
                  timeout: 3000,
                })
              })
          } else {
            reject()
          }
        })
      })
    },
    repeateAgain() {
      this.ruleData.rules.push({ id: '', rule: '' })
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
      this.initTrHeight()
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.ruleData.rules.splice(index, 1)
      this.initTrHeight()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.refFormObserver.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-rule-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
</style>
